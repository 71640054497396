.columnWith > thead > tr > th,
.columnWith > tbody > tr > td,
{
  min-width: 5rem;
  max-width: 20rem;
}

.btnColumn {
  width: 100px;
}

@media (max-width: 767px) {
  .btnColumn {
    width: 60px;
  }
}