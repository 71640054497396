.loginWrapper {
  max-width: 500px;
  margin-top: 15vh;
  margin-left: auto;
  margin-right: auto;
}

.loginCard {
  max-width: 400px;
  border-radius: 15px;
  padding: 1rem
}

.inputField {
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 0.3rem
}

.formControl,
.formControl:focus {
  border: none;
  outline: none;
  box-shadow: none;
}

.formControl:-webkit-autofill,
.formControl:-webkit-autofill:hover,
.formControl:-webkit-autofill:focus,
.formControl:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
}