.columnWith > thead > tr > th,
.columnWith > tbody > tr > td,
{
  max-width: 20rem;
}

.checkboxWidth {
  text-align: center;
  vertical-align: middle;
  width: 36px;
}

.columnTitleWidth {
  min-width: 9rem;
}

.columnDetailsWidth {
  min-width: 220px;
}

