.kiwanisBrand {
  padding-top: 1em;
  padding-bottom: 1em;
  padding-left: 20px;
  display: flex;
  align-items: center;
}

.kiwanisIconLogo {
  padding-right: 0.5em;
}

.kiwanisIconLogo img {
  height: 40px;
}

.kiwanisLogo img {
  height: 50px;
}
