@import "../../../../styles/kiwanis-colors.scss";

.toggleContainer {
  width: 66px;
  background-color: #e2e2e2;
  cursor: pointer;
  user-select: none;
  border-radius: 3px;
  padding: 2px;
  height: 32px;
  position: relative;
  //z-index: -1;
}

.dialogButton {
  font-size: 11px;
  line-height: 12px;
  font-weight: bold;
  cursor: pointer;
  background-color: $kiwanis-blue-color;
  color: white;
  padding: 8px 12px;
  border-radius: 18px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  min-width: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  min-width: unset;
  border-radius: 3px;
  box-sizing: border-box;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  position: absolute;
  left: 34px;
  transition: all 0.3s ease;
}

.disabled {
  background-color: $kiwanis-gray-color;
  left: 2px;
}